<template>
  <div class="home">
    <v-app-bar color="indigo darken-1" dark clipped-left app class="no-print">
      <v-toolbar-title>{{ "勤怠管理" }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- TMPアバターチップ -->
      <v-chip
        class="ma-2"
        color="lime darken-4"
        text-color="white"
        @click="logout"
      >
        <template>
          <v-avatar left>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          {{ $store.state.name }}
        </template>
      </v-chip>
    </v-app-bar>

    <v-main>
      <v-container>
        <div>
          <div class="text-center">
            <p class="text-h5">
              {{ date.toLocaleDateString() }}{{ &nbsp; }}
              {{ date.toLocaleTimeString() }}
            </p>
          </div>
          <hr />
          <!--<v-row class="mt-5">
            <v-autocomplete
              outlined
              v-model="selectDivision"
              :items="divisions"
              @input="changeDivision"
              prepend-icon="mdi-form-select"
              label="事業部"
              item-text="name"
              item-value="code"
              dense
              class="mx-5"
            ></v-autocomplete>
          </v-row>
          <v-row>
            <v-autocomplete
              v-model="selectAffiliation"
              :items="affiliations"
              outlined
              prepend-icon="mdi-form-select"
              label="所属"
              item-text="name"
              item-value="code"
              dense
              class="mx-5"
            ></v-autocomplete>
          </v-row>
          <v-row>
            <v-autocomplete
              v-if="showMeal"
              v-model="selectHadMeal"
              :items="hadMealItems"
              outlined
              prepend-icon="mdi-form-select"
              label="食事"
              item-text="name"
              item-value="code"
              dense
              class="mx-5"
            ></v-autocomplete>
          </v-row>
          <hr />
          <v-row class="mt-5">
            <div class="mx-5" style="width:100%">
              <v-btn
                @click="setAttendance(false)"
                color="success"
                class="btn"
                style="height:50px;"
                ><template
                  ><v-icon>mdi-briefcase-clock</v-icon
                  ><span>出勤</span></template
                ></v-btn
              >
            </div>
          </v-row>
          <v-row>
            <div class="ma-5" style="width:100%">
              <v-btn
                @click="setAttendance(true)"
                color="error"
                class="btn"
                style="height:50px;"
                ><template
                  ><v-icon>mdi-exit-run</v-icon><span>退勤</span></template
                ></v-btn
              >
            </div>
          </v-row> -->
          <v-row class="mt-5">
            <div class="mx-5" style="width:100%">
              <v-btn @click="roster" class="btn" style="height:50px;"
                ><template
                  ><v-icon>mdi-file-table</v-icon
                  ><span>勤務実績</span></template
                ></v-btn
              >
            </div>
          </v-row>
          <v-row class="mt-5">
            <div class="mx-5" style="width:100%">
              <v-btn
                @click="vacationrequest"
                class="btn"
                style="height:50px;"
                color="error"
                ><template
                  ><v-icon>mdi-file-table</v-icon
                  ><span>休暇申請</span></template
                ></v-btn
              >
            </div>
          </v-row>
          <v-row class="mt-5">
            <div class="mx-5" style="width:100%">
              <v-btn
                @click="shiftrequest"
                class="btn"
                style="height:50px;"
                color="success"
                ><template
                  ><v-icon>mdi-file-table</v-icon
                  ><span>シフト申請</span></template
                ></v-btn
              >
            </div>
          </v-row>
          <v-row class="mt-5">
            <div class="mx-5" style="width:100%">
              <v-btn
                @click="attendancerequest"
                class="btn"
                style="height:50px;"
                color="primary"
                ><template
                  ><v-icon>mdi-file-table</v-icon
                  ><span>打刻変更・残業申請</span></template
                ></v-btn
              >
            </div>
          </v-row>
        </div>
      </v-container>
    </v-main>
  </div>
</template>

<script>
//mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";
const DATE = new Date();

export default {
  name: "UserTop",
  mixins: [Common, ShowDialogs, Api],
  data: () => ({
    date: DATE,
    divisions: [],
    selectDivision: {},
    affiliations: [],
    selectAffiliation: {},
    affiliationsItem: [],
    targetDivisions: ["1", "2", "3", "9"],
    targetdate: `${DATE.getFullYear()}-${DATE.getMonth() +
      1}-${DATE.getDate()}`,
    showMeal: false,
    selectHadMeal: {},
    hadMealItems: []
  }),
  methods: {
    changeDivision() {
      console.log("changeDivision", this.selectDivision);
      this.affiliations = this.affiliationsItem.filter(
        e => e.refcode === this.selectDivision
      );
      this.showMeal = this.selectDivision === "2" ? true : false;
    },
    async setAttendance(isEnd = false) {
      console.log("setAttendance", isEnd);

      console.log("a", this.selectDivision, this.selectAffiliation);

      if (this.targetDivisions.indexOf(this.selectDivision) < 0) {
        this.$warning("事業部が選択されていません");
        return false;
      }

      if (!this.selectAffiliation || this.selectAffiliation === "0") {
        this.$warning("所属が選択されていません");
        return false;
      }
      this.$loading();
      try {
        const param = {
          userIncode: this.$store.state.userincode,
          shozokuIncode: this.selectAffiliation,
          hadMeal: this.selectDivision === "2" ? this.selectHadMeal : "0"
        };

        const path = !isEnd
          ? this.Paths.attendanceStartSp
          : this.Paths.attendanceEndSp;

        const result = await this.$post(path, param);
        if (result) this.$info(result.displaymessage);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        if (e.message === "認証トークンの有効期限切れです") {
          this.$store.commit("logout");
          this.$close();
          this.$router.push({ name: "Login" });
        }
      } finally {
        this.$unloading();
      }
    },
    async roster() {
      console.log("roster");
      this.$router.push({ name: "UserRoster" });
    },
    async vacationrequest() {
      console.log("vacationrequest");
      const params = {
        items: this.$store.state.userincode
      };
      this.$router.push({ name: "UserVacationRequest", params });
    },
    async getPulldown() {
      console.log("getPulldown");
      const codeMasters = await this.$get(this.Paths.codeMasterPulldown);
      this.divisions = codeMasters.filter(e => e.refcode === CodeGroup.DIVISI);
      console.log(this.$store.state.shozokuCode1);
      if (
        this.$store.state.shozokuCode1 &&
        this.targetDivisions.indexOf(this.$store.state.shozokuCode1) >= 0
      ) {
        this.selectDivision = this.$store.state.shozokuCode1;
      } else {
        this.selectDivision = "1";
      }

      this.affiliationsItem = await this.$get(this.Paths.shozoku);
      if (this.targetDivisions.indexOf(this.selectDivision) >= 0) {
        this.affiliations = this.affiliationsItem.filter(
          e => e.refcode === this.selectDivision
        );
      } else {
        this.affiliations = this.affiliationsItem;
      }
      if (this.$store.state.baseShozoku) {
        this.selectAffiliation = this.$store.state.baseShozoku;
      }

      this.hadMealItems = codeMasters.filter(
        e => e.refcode === CodeGroup.EXISTE && e.code !== NotSetCode.EXISTE
      );

      this.showMeal = this.selectDivision === "2" ? true : false;
      this.selectHadMeal = this.$store.state.hadMeal
        ? this.$store.state.hadMeal
        : "0";
    },
    async logout() {
      console.log(this.$store);
      if (await this.$confirm("ログアウトしますか？")) {
        this.$store.commit("logout");
        console.log(this.$store);
        this.$router.push("/");
      }
    }
  },
  async created() {
    console.log(this.$store);
    await this.getPulldown();
  },
  mounted() {
    setInterval(() => {
      this.date = new Date();
    }, 500);
  }
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  font-size: 25px;
}
</style>
